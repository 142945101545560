@import '../../styles/shared.scss';


.login {
    width: 100vw;
    height: 100vh;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: calc(100vh * 0.15);


    &__content{
        display: flex;
        flex-direction: column;
        align-items: center;

        
        width: 408px;
        padding: 50px 20px;
        background-color: $white;
        border-radius: 20px;
        box-shadow: $border-shadow;
    
    }


    &__logo{
        width: 227px;
        height: 44px
    }

    &__button{
        width: 308px;
    }

    &__apple--button{
        margin: 60px 0 12px;
    }

    &__error{
        width: 308px;
        margin-top: 5px;
        @include text_small_regular;
        color: $red;
    }

    &__privacy{
        display: flex;
        margin-top: 120px;
        align-items: center;

        & > p{
            @include text_small_bold;
            color: $purple;
            text-decoration: underline;
            cursor: pointer;
            display: inline-block;
        }

        &--dot{
            width: 4px;
            height: 4px;
            border-radius: 12px;
            background: $medium-gray;
            margin: 0 8px;
            cursor: default;
        }
    }

}