@font-face {
  font-family: "Eudoxus Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/EudoxusSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Eudoxus Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/EudoxusSans-Bold.woff") format("woff");
}
