.loader{
    animation: rotation 1.5s infinite linear;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(359deg);
      }
    }
}