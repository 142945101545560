// fonts
$primary-font: "Eudoxus Sans";

// colors
$white: #ffffff;
$black: #000000;

$purple: #7d4afb;
$purple-dark: #20153B;
$purple-01: rgba(125, 74, 251, 0.1);
$purple-90: #20153c;


$red: #ff3c3c;
$red_01: rgba(255, 60, 60, 0.1);

$gray: #c7ced9;
$light-gray: #f5f6f8;
$dark-gray: #8a93a0;
$medium-gray: #e2e4e7;

$primary-color: $black;

$secondary-color: $purple;

$background-color: $white;

$transparent: transparent;

$text-color: $primary-color;
$text-color-10: #54585f;

$error-color: #ff3c3c;

$border-shadow: 0px 4px 16px 0px rgba(36, 31, 39, 0.15),
  0px 2px 4px 0px rgba(36, 31, 39, 0.05);
$select-shadow: 0px 2px 12px 0px rgba(36, 31, 39, 0.1);
