@import "../../styles/shared.scss";

.button {
  width: 100%;
  height: 48px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  @include text_large_bold;
  outline: none;
  border: none;
  overflow: hidden;

  &__loader {
    width: 20px;
    height: 20px;
    animation: rotation 1.2s infinite linear;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(359deg);
      }
    }
  }
}


.gradientButton {
  background: linear-gradient(78deg, #8826ff 0%, #42ffe8 100%);
  color: $white;

  &:active {
    background: linear-gradient(78deg, #a459ff 0%, #8ffff2 100%);
  }

  &.disable {
    background: $gray;
    color: $light-gray;
    cursor: default;
  }
}

.grayButton {
  background-color: $light-gray;
  color: $black;

  &:active {
    color: $dark-gray;
  }

  &.disable {
    color: $gray;
    cursor: default;
  }
}

.whiteButton {
  border: 1px solid $medium-gray;
  background-color: $white;
  color: $black;

  &:active {
    color: $dark-gray;
  }

  &.disable {
    color: $medium-gray;
    cursor: default;
  }
}