@import "vars";

@mixin heading_1 {
  font-family: $primary-font;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  color: $text-color;
}

@mixin heading_2 {
  font-family: $primary-font;
  font-weight: 700;
  font-size: 20px;
  color: $text-color;
  line-height: 24px;
}

@mixin heading_3 {
  font-family: $primary-font;
  font-weight: 700;
  font-size: 18px;
  color: $text-color;
  line-height: 24px;
}

@mixin heading_4 {
  font-family: $primary-font;
  font-weight: 700;
  font-size: 16px;
  color: $text-color;
  line-height: 22px;
}

@mixin heading_5 {
  font-family: $primary-font;
  font-weight: 700;
  font-size: 14px;
  color: $text-color;
  line-height: 20px;
}

@mixin heading_6 {
  font-family: $primary-font;
  font-weight: 700;
  font-size: 12px;
  color: $text-color;
  line-height: 16px;
}

@mixin text_large_regular {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 16px;
  color: $text-color;
  line-height: 22px;
}

@mixin text_large_bold {
  font-family: $primary-font;
  font-weight: 700;
  font-size: 16px;
  color: $text-color;
  line-height: 22px;
}

@mixin text_medium_regular {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 14px;
  color: $text-color;
  line-height: 20px;
}

@mixin text_medium_bold {
  font-family: $primary-font;
  font-weight: 700;
  font-size: 14px;
  color: $text-color;
  line-height: 20px;
}

@mixin text_small_regular {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 12px;
  color: $text-color;
  line-height: 16px;
}

@mixin text_small_bold {
  font-family: $primary-font;
  font-weight: 700;
  font-size: 12px;
  color: $text-color;
  line-height: 16px;
}

@mixin text_x_small_regular {
  font-family: $primary-font;
  font-weight: 400;
  font-size: 10px;
  color: $text-color;
  line-height: 14px;
}

@mixin text_x_small_bold {
  font-family: $primary-font;
  font-weight: 700;
  font-size: 10px;
  color: $text-color;
  line-height: 14px;
}
